<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text" dense flat>
      Rating Levels
      <v-spacer></v-spacer>
      <v-text-field
        :slot="sm ? 'extension' : null"
        color="color3"
        append-icon="fas fa-search"
        label="Search"
        hide-details
        v-model="search"
        solo dense
        clearable
        :class="{'mb-3': sm}"
      ></v-text-field>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid>
      <v-row dense>
        <!-- RATINGS TABLE -->
        <v-col cols="12" sm="6" md="4" class="pr-0">
          <v-data-table
            :headers="[
              { text: 'Id', value: 'id', align: 'center'},
              { text: 'Rating', value: 'rating', align: 'center'},
              { text: 'Numeric Value', value: 'value', align: 'center'},
              { text: '', value: 'actions', align: 'end'}
            ]"
            :items="ratingSystem.ratings"
            hide-default-footer
            :options="{ itemsPerPage: -1 }"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:[`item`]="{item}">
              <tr :class="selected && item.id === selected.id ? 'color3 color3Text--text' : ''" @click="selectedId = item.id">
                <td class="text-center">{{item.id}}</td>
                <td class="text-center">{{item.rating}}</td>
                <td class="text-center">{{item.value}}</td>
                <td class="text-end">
                  <v-btn color="color3" text small @click="selectedId = item.id" :disabled="editing">
                    view rules
                    <v-icon class="ml-2">fas fa-caret-right</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
            <v-btn
              color="color3"
              small text
              @click.stop="addLevel"
              :disabled="unsavedLevel"
            >
              Add Level
            </v-btn>
          </div>
        </v-col>
        <!-- RATINGS RULES -->
        <v-col cols="12" sm="6" md="8" class="pl-0">
          <v-slide-x-reverse-transition>
            <v-card
              flat
              class="fill-height"
              color="color3 color3Text--text"
              v-if="selected"
              tile
            >
              <v-slide-x-reverse-transition leave-absolute mode="out-in" group>
                <v-card-text :key="selected.id" class="color3Text--text">
                  <div class="text-end">
                    <v-btn color="color3Text" text x-small @click.stop="editRse">Santioning Expression</v-btn>
                  </div>
                  <div class="text-center title">
                    Ways to earn a rating of {{selected.rating}}
                  </div>
                  <v-list two-line class="color3 color3Text--text">
                    <template v-for="(rule, i) in selected.rules">
                      <v-divider :key="`${rule.id}d`" v-if="i > 0"></v-divider>
                      <v-list-item :key="rule.id">
                        <v-list-item-content>
                          <v-list-item-title class="color3Text--text">From Playing: {{getDivisionName(rule.divisionId)}}</v-list-item-title>
                          <v-list-item-subtitle class="color3Text--text" v-text="rule.expression"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            color="color3Text color3--text"
                            small fab
                            @click.stop="editRule(rule)"
                          >
                            <v-icon>fas fa-pencil</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-btn color="color3Text" text icon small @click.stop="deleteId = rule.id">
                            <v-icon small>fas fa-trash</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card-text>
                <v-card-actions class="justify-center" :key="`${selected.id}-2`">
                  <v-btn color="color3Text" text @click.stop="newRule">Add New Rule</v-btn>
                </v-card-actions>
              </v-slide-x-reverse-transition>
            </v-card>
          </v-slide-x-reverse-transition>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      scrollable
      :persistent="rule && !!(rule.divisionId || rule.expression)"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>Rule Editor</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="rule" class="pt-3">
          <div class="caption mt-n3 mb-3">Rule Id: {{rule.id}} | Rating Id: {{selected.id}}</div>
          <v-select
            :items="options.divisionOptions"
            v-model="rule.divisionId"
            label="Division Rule Applies To"
            item-text="name"
            item-value="id"
            color="color3"
            item-color="color3"
            outlined
          ></v-select>
          <v-textarea
            label="Expression"
            v-model="rule.expression"
            outlined
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success white--text"
            small fab
            :disabled="!ruleDirty"
            :loading="loading"
            @click.stop="saveRule"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
          <v-btn
            color="error white--text"
            small fab
            :loading="loading"
            @click.stop="dialog=false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog2"
      scrollable
      :persistent="rseDirty"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card v-if="!!selected">
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>{{selected.rating}} Sanctioning</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-3">
          <div class="caption mt-n3 mb-3">Rating Id: {{selected.id}}</div>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Rating"
                v-model="newRating"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Value"
                v-model="newValue"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Expression"
                v-model="sanctioningExpression"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success white--text"
            small fab
            :disabled="!rseDirty"
            :loading="loading"
            @click.stop="patchRSE"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
          <v-btn
            color="error white--text"
            small fab
            :loading="loading"
            @click.stop="cancel"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vbl-confirm-dialog
      :ask="deleteId > 0"
      :loading="loading"
      @confirm="deleteRule"
      @deny="deleteId = 0"
    ></vbl-confirm-dialog>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, integer } from 'vuelidate/lib/validators'

export default {
  props: ['ratingSystem', 'options'],
  mixins: [validationMixin],
  validations () {
    return {
      model: {
        name: { required },
        shortName: { required },
        dateLimitValue: { required, integer },
        dateLimitPart: { required }
      }
    }
  },
  data () {
    return {
      editing: false,
      loading: false,
      editable: null,
      selectedId: null,
      rule: null,
      ruleOg: null,
      dialog: false,
      dialog2: false,
      window: 1,
      sanctioningExpression: null,
      deleteId: 0,
      search: null,
      newRating: null,
      newValue: null
    }
  },
  computed: {
    sm () {
      return this.$vuetify.breakpoint.smAndDown
    },
    selected () {
      return this.selectedId !== null && this.ratingSystem.ratings.find(f => f.id === this.selectedId)
    },
    ruleDirty () {
      return this.rule && this.ruleOg && JSON.stringify(this.rule) !== JSON.stringify(this.ruleOg) && this.rule.expression
    },
    rseDirty () {
      return this.selected && this.selected.sanctioningExpression !== this.sanctioningExpression
    },
    unsavedLevel () {
      return !!this.ratingSystem.ratings.find(f => f.id === 0)
    }
  },
  methods: {
    editRse () {
      this.sanctioningExpression = this.selected.sanctioningExpression
      this.newValue = this.selected.value
      this.newRating = this.selected.rating
      this.dialog2 = true
    },
    editRule (rule) {
      this.ruleOg = rule
      this.rule = JSON.parse(JSON.stringify(rule))
      this.dialog = true
    },
    newRule () {
      const r = { id: 0, divisionId: '', expression: '' }
      this.editRule(r)
    },
    cancel () {
      this.dialog = false
      this.dialog2 = false
      this.rule = null
      this.sanctioningExpression = null
      this.newRating = null
      this.newValue = null
      if (this.unsavedLevel) {
        this.ratingSystem.ratings = this.ratingSystem.ratings.filter(f => f.id > 0)
      }
      this.selectedId = null
    },
    deleteRule () {
      this.loading = true
      const dto = {
        id: this.deleteId,
        deleted: true
      }
      this.$VBL.ratings.postRule(this.selected.id, dto)
        .then((response) => {
          this.$emit('changed', response.data)
          this.deleteId = 0
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveRule () {
      if (!this.ruleDirty) return
      this.loading = true
      this.$VBL.ratings.postRule(this.selected.id, this.rule)
        .then((response) => {
          this.$emit('changed', response.data)
          this.cancel()
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    patchRSE () {
      if (!this.rseDirty) return
      this.loading = true
      const dto = { id: this.selectedId, sanctioningExpression: this.sanctioningExpression }
      if (dto.id === 0) {
        dto.value = this.newValue
        dto.rating = this.newRating
      }
      this.$VBL.ratings.postRating(dto, this.ratingSystem.id)
        .then((response) => {
          this.$emit('changed', response.data)
          this.cancel()
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getDivisionName (id) {
      const d = this.options && this.options.divisionOptions.find(f => f.id === id)
      return d ? d.name : id
    },
    addLevel () {
      this.ratingSystem.ratings.push({
        id: 0,
        rating: null,
        value: null
      })
      this.$nextTick(() => {
        this.selectedId = 0
        this.dialog2 = true
      })
    }
  },
  watch: {
    active: 'loadOptions'
  }
}
</script>
